let PostMessageManager = class PostMessageManager {
  constructor(bridge){
    if (!bridge.postMessageTargetOrigin) {
      this.enabled = false;
    } else {
      if(bridge.postMessageTargetOrigin === '*'){
        this.enabled = false;
        console.warn("For security reasons postMessageTargetOrigin can't be the wildcard '*'. See docs for more info. ");
      } else {
        this.enabled = true;
        this.postMessageTargetOrigin = bridge.postMessageTargetOrigin;
      }
    }
    this.componentConnector = bridge.ComponentConnector;
    this.bridgeChannelManager = bridge.BridgeChannelManager;
  }

  /**
   * Handles the postMessage events sent to this window from its parent.
   * It transforms them into channels __bridge_post_message_{your-event-name} including the payload, to do so the postMessage must be:
   * {
   *  "event" : "your-event-name",
   *  "detail" : <any>
   * }
   * Creates and listens to the private channel __bridge_ch_send_post_message to send postMessages to parent window.
   */
  setupPostMessages() {
    if(this.enabled) {
      window.addEventListener('message', ({data}) => {
        if(data.event){
          let channel = this.bridgeChannelManager.getPostMessageChannel(data.event);
          const evt = this.componentConnector.createEvent(data.event, data.detail);
          channel.next(evt);
        }
      });
      this._sendPostMessage({ event: '__bridge-ready' });
      this.bridgeChannelManager.getBridgeChannel('send_post_message').subscribe(evt => this._sendPostMessage(evt.detail));
    }
  }

  _sendPostMessage(evt) {
    window.parent && window.parent.postMessage({ eventName: evt.event, eventDetail: evt.detail }, this.postMessageTargetOrigin);
  }
}

export default PostMessageManager;