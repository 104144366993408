export default class Route {
  static PARAM = /(?::([^/]+))/g;
  static TRAILING_SLASHES = /\/*$/;
  static _404_PAGE_NAME = '404';

  name = '';
  pattern = '';
  regexp = null;
  redirectPage = null;
  isAccessible = true; // needed to discriminate between 404 and rest of pages with same pattern

  /**
   * @private
   * @param {String} routeName
   * * @param {String} pattern
   * @return {Object}
   */
  constructor(name, pattern) {
    this.name = name;
    this.pattern = pattern;
    var regexp = pattern
      .replace(Route.PARAM, '([^/]+)')
      .replace(Route.TRAILING_SLASHES, '/*');
    this.regexp = new RegExp('^' + regexp + '$');
    var parts;
    var keys = [];
    while ((parts = Route.PARAM.exec(pattern)) !== null) {
      keys.push(parts[1]);
    }
    this.keys = keys;
  }

  path(params) {
    params = params || {};
    this.params = {};
    var parts;
    var path = this.pattern;
    while ((parts = Route.PARAM.exec(this.pattern)) !== null) {
      path = path.replace(parts[0], params[parts[1]]);
      this.params[parts[1]] = params[parts[1]];
    }
    var queryParams = [];
    for (var param in params) {
      if (!this.params.hasOwnProperty(param)) {
        queryParams.push(param + '=' + encodeURIComponent(params[param]));
      }
    }
    if (queryParams.length) {
      path += '?' + queryParams.join('&');
    }
    return path;
  }

  matchPath(path) {
    return path.match(this.regexp);
  }

  parsePath(path) {
    var match = this.matchPath(path);
    this.params = {};
    if (match) {
      var i = 1;
      var parts;
      while ((parts = Route.PARAM.exec(this.pattern)) !== null) {
        this.params[parts[1]] = this._parseParam(match[i]);
        i++;
      }
    }
  }

  parseQuery(query) {
    this.query = query;
    for (var queryParam in this.query) {
      if(this.query.hasOwnProperty(queryParam)){
        this.params[queryParam] = this.query[queryParam];
      }
    }
  }

  /**
   * @return {Boolean}
   */
  is404() {
    return this.name === Route._404_PAGE_NAME;
  }

  /**
   * @private
   * @param {String} value
   * @return {Boolean}
   */
  _isNumber(value) {
    return parseInt(value) + '' === value || parseFloat(value) + '' === value;
  }

  /**
   * @private
   * @param {String} param
   * @return {String|Number}
   */
  _parseParam(param) {
    if (this._isNumber(param)) {
      param = +param;
    }

    return param;
  }

  handler() {
    // Overwrite to make something with the current route
  }

}
