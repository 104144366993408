export default class NavigationStack {
  constructor() {
    this.navStack = [];
    this.skipNav = {};
  }

  get length() {
    return this.navStack.length;
  }


  addSkipNavigation(nav) {
    if (nav.skipHistory === true || nav.skipHistory === false) {
      this.skipNav[`${nav.from}:${nav.to}`] = nav.skipHistory;
    }
  }

  _reverseNavigation(nav) {
    return {
      from: nav.to,
      to: nav.from
    };
  }

  isSkipNavigation(nav) {
    return this.skipNav[`${nav.from}:${nav.to}`] === true;
  }

  lastNavigation() {
    const navCount = this.navStack.length;
    let from;
    let to;
    if (navCount === 1) {
      to = this.navStack[0];
    } else if (navCount > 1) {
      to = this.navStack[navCount-1];
      from = this.navStack[navCount-2];
    }
    return this.createNavigation(from, to);
  }

  createRoute(page, params = {}) {
    return {
      page,
      params
    };
  }

  createNavigation(routeFrom, routeTo) {
    return {
      from: routeFrom ? routeFrom.page : undefined,
      to: routeTo ? routeTo.page : undefined
    };
  }

  push(route) {
    if (!this.top() || this.top().page !== route.page) {
      this.navStack.push(route);
    }
  }

  replace(route) {
    if (!this.top() || this.top().page !== route.page) {
      this.navStack[this.navStack.length - 1] = route;
    }
  }

  pop() {
    return this.navStack.pop();
  }

  top() {
    return this.navStack.length > 0 ? this.navStack[this.navStack.length - 1] : undefined;
  }

  isBackwardNavigation(newNav) {
    const lastNav = this.lastNavigation();
    return lastNav && newNav.from === lastNav.to && newNav.to === lastNav.from;
  }

  update(routeFrom, routeTo) {
    const nav = this.createNavigation(routeFrom, routeTo);
    if (this.isBackwardNavigation(nav)) {
      while(this.isSkipNavigation(this._reverseNavigation(this.lastNavigation()))) {
        this.pop();
      }
      this.pop();
    } else {
      this.push(routeTo);
    }
    return this.top();
  }

  clear() {
    this.navStack = [];
  }

  replaceRoute(route) {
    if (this.navStack.length > 0) {
      this.navStack[this.navStack.length - 1] = route;
    } else {
      this.push(route);
    }
  }

  /**
   * Clear the router stack until given page is found on router stack.
   */
  clearUntil(targetPage) {

    if (this.navStack.find(route=>route.page === targetPage)) {
      let currentRoute = null;
      do {
        currentRoute = this.navStack.pop();
      } while (currentRoute && currentRoute.page != targetPage);
    }
  }
}
