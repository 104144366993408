import { Subscription } from 'rxjs';

export default class Subscriptor {
  constructor(node) {
    this.node = node;
    this.subscriptions = [];
    this.publications = new Subscription();
  }

  hasSubscription(channel) {
    return(this.subscriptions.filter(d => d.channel === channel).length > 0);
  }

  /**
   * Publish an event.sss
   *
   * @param  {Event} event
   */
  publish(event) {
    this.publications.add(event);
  }

  makeCallbackWithNoReplay(channel, fn) {
    if (!fn.node.intervals) {
      fn.node.intervals = [];
    }
    if (!fn.node.ids) {
      fn.node.ids = [];
    }
    let getTimeFromNode = function(node) {
      return node.intervals[channel.name];
    };
    let setTimeForNode = function(node, time) {
      node.intervals[channel.name] = time;
    };
    let getTimeFromChannel = function(channel) {
      return channel._events.length > 0 ? channel._events[0].time : 1;
    };
    let getIdFromNode = function(node) {
      return node.ids[channel.name];
    };
    let setIdForNode = function(node, id) {
      node.ids[channel.name] = id;
    };
    let getIdFromChannel = function(channel) {
      return channel._events.length > 0 ? channel._events[0].uuid : null;
    };
    let fnReplayOff = function() {
      let lastInterval = getTimeFromChannel(channel);
      let nodeInterval = getTimeFromNode(fn.node);
      let lastId = getIdFromChannel(channel);
      let nodeId = getIdFromNode(fn.node);
      if (!nodeInterval || nodeInterval < lastInterval ||
        (nodeInterval === lastInterval && (nodeId != lastId))) {
        setTimeForNode(fn.node, lastInterval);
        setIdForNode(fn.node, lastId);
        return fn.apply(this, arguments);
      }
      setTimeForNode(fn.node, lastInterval);
    }.bind(this);
    fnReplayOff.node = fn.node;
    return fnReplayOff;
  }

  /**
   * Subscribe to a channel.
   *
   * @param  {Channel}   channel  Channel to subscribe
   * @param  {Function}  fn       Callback function to run when a value from a channel changed
   */
  subscribe(channel, fn, previousState, bind) {
    if (!this.hasSubscription(channel)) {
      let callback = fn;
      let subscription;
      if (previousState === false) {
        callback = this.makeCallbackWithNoReplay(channel, fn);
      }

      let pos = this._firstInstanceOfObserver(callback.node, channel);
      if(pos === -1) {
        subscription = channel.subscribe(callback);
        pos = channel.observers.length - 1;
      } else {
        subscription = channel.observers[pos];
      }

      subscription.node = callback.node;

      const subscriptionDetail = {
        channel,
        bind,
        observer:  subscription,
        subscription: subscription
      };

      this.subscriptions.push(subscriptionDetail);
    }
  }

  /**
   * Remove all active subscriptions.
   */
  unsubscribe(cleanPrivateChannels) {
    this.subscriptions.forEach(({channel, observer}) => {
      let index = channel.observers.indexOf(observer);
      if(index >= 0) {
        if (cleanPrivateChannels === true || !channel.name.match(/\b__bridge_/)) {
          channel.unsubscribe(index);
        }
      }
    });

    this.publications.unsubscribe();
  }

  /**
   * Returns the position of the first occurrence of the observer's node in the channel.
   * If the node has none observer registered to the channel, it returns -1.
   *
   * @param {node}
   * @param {channel}
   */
  _firstInstanceOfObserver(node, channel) {
    return channel.observers.findIndex(obs => obs.node === node);
  }
}
