import Route from './route';
import { Subscription, fromEvent, merge } from 'rxjs';
import { distinctUntilChanged, map, startWith, filter } from 'rxjs/operators';
import eventManager from './manager/events';
import NavigationStack from './navigation-stack';
import Constants from './constants';

const { EMPTY } = Subscription;
const { externalEventsCodes } = Constants;

let instance = null;

let _useHistory = false;
let _routes = {};
let _disposables;
let _currentRoute;
let _404Route;
let _channelManager;

/**
 * @class SerialSubscription
 * Mimics behavior of SerialDisposable in RxJS v4,
 * allows to add only single subscription. If new subscription's added,
 * existing subscription will be unsubscribed.
 *
 * By design of RxJS v5 it is no longer recommended to manage subscription
 * imperatively vis various kind of subscription, reason it only have single
 * kind of composite subscription. This implementation is for interop between
 * existing codebases.
 * @extends {Subscription}
 */
class SerialSubscription extends Subscription {
  constructor() {
    super();
    this._currentSubscription = EMPTY;
  }

  /**
   * Adds a tear down to be called during the unsubscribe() of this
   * Subscription.
   *
   * If there's existing subscription, it'll be unsubscribed and
   * removed.
   *
   * @param {TeardownLogic} teardown The additional logic to execute on
   * teardown.
   * @return {Subscription} Returns the Subscription used or created to be
   * added to the inner subscriptions list. This Subscription can be used with
   * `remove()` to remove the passed teardown logic from the inner subscriptions
   * list.
   */
  add(teardown) {
    if (this.closed) return;
    if (typeof(teardown) === 'function') teardown = new Subscription(teardown);

    if (this._currentSubscription) {
      this.remove(this._currentSubscription);
      this._currentSubscription.unsubscribe();
      this._currentSubscription = null;
    }

    super.add(this._currentSubscription = teardown);
  }
}

export default class Router {
  static SUPPORTS_HISTORY_API = window.history && 'pushState' in window.history;

  static PARAM = /(?::([^/]+))/g;
  static LTRIM_SLASH = /^\/(\b)/;
  static EMPTY = /^$/;

  static HASH_PREFIX = /^#!?\/*/;
  static PATH_PREFIX = /^\/*/;

  static isNavigationInProgress = false;
  static cancelledNavigation;
  static hashIsDirty = false;

  navigationStack;

  interceptorContext = {};

  constructor() {
    const { TEMPLATE_TRANSITION_END} = externalEventsCodes;

    if (!instance) {
      instance = this;
    }

    this.navigationStack = this._createNavigationStack();

    eventManager.on(TEMPLATE_TRANSITION_END, () => {
      this.isNavigationInProgress = false;
    });

    return instance;
  }

  _createNavigationStack() {
    return new NavigationStack();
  }

  /**
   * @param {Boolean} value
   */
  set useHistory(value) {
    /* istanbul ignore else */
    if (Router.SUPPORTS_HISTORY_API) {
      _useHistory = value;
    }
  }

  get useHistory() {
    return _useHistory;
  }

  set routes(routes) {
    _routes = routes;
  }

  get routes() {
    return _routes;
  }

  get currentRoute() {
    return _currentRoute;
  }

  set currentRoute(route) {
    _currentRoute = route;
  }

  get channelManager() {
    return _channelManager;
  }

  set channelManager(channelManager) {
    _channelManager = channelManager;
  }

  /**
   * @param {Object} route
   */
  // eslint-disable-next-line no-unused-vars
  handler(route) {
    // Overwrite to make something after all matched routes
  }

  addRoute(name, pattern) {
    this.routes[name] = new Route(name, pattern);
    return this.routes[name];
  }

  addRoutes(routes) {
    var __routes = {};
    for (var routeName in routes) {
      if(routes.hasOwnProperty(routeName)) {
        __routes[routeName] = this.addRoute(routeName, routes[routeName]);
      }
    }
    return __routes;
  }

  addSkipNavigations(skipNavs) {
    for (let i = 0; i<skipNavs.length; i++) {
      this.navigationStack.addSkipNavigation(skipNavs[i]);
    }
  }

  /**
   * @private
   * @return {String}
   */
  _getHashPath() {
    return location.hash
      .replace(Router.HASH_PREFIX, '/')
      .replace(Router.EMPTY, '/');
  }

  /**
   * @private
   * @return {Observable}
   */
  _observeHashChange() {
    return fromEvent(window, 'hashchange').pipe(
      map(this._getHashPath),
      startWith(this._getHashPath())
    );
  }

  /**
   * @private
   * @return {String}
   */
  _getURLPath() {
    return location.pathname.replace(Router.PATH_PREFIX, '/');
  }

  /**
   * @private
   * @return {Observable}
   */
  _observeStateChange() {
    return merge(
      fromEvent(window, 'popstate'),
      fromEvent(window, 'pushstate')
    ).pipe(
      map(this._getURLPath),
      startWith(this._getURLPath())
    );
  }

  /**
   * @private
   * @param {String}
   * @return {Array}
   */
  matchRoute(fullPath) {
    var route;
    var [path, query] = fullPath.split('?');
    query = this._parseQuery(query);
    for (var routeName in this.routes) {
      if(this.routes.hasOwnProperty(routeName)){
        route = this.routes[routeName];
        if ((!route.is404() || route.isAccessible) && route.matchPath(path)) {
          route.parsePath(path)
          route.parseQuery(query);
          return route;
        }
      }
    }
  }

  _parseQuery(querystr) {
    const params = {};
    if (querystr) {
      // Split into key/value pairs
      const queries = querystr.split('&');
      if (queries) {
        // Convert the array of strings into an object
        let key, value, i, len = queries.length;
        for (i = 0; i < len; i++) {
          [key, value] = queries[i].split('=');
          params[key] = decodeURIComponent(value);
        }
      }
    }
    return params;
  }

  _setup404() {
    const route404 = this.routes[Route._404_PAGE_NAME];

    // We check if 404 route have a pattern...
    if (route404 && route404.pattern !== '') {
      const routeWithSamePattern = this.getRouteWithPattern(route404.pattern);

      // We set redirect page based on, if it's a repeated URL pattern or a unique one.
      // If it's unique, we set it accesible from the router.
      // Otherwise, it's going to NOT be accesible from the router (multiple router with same pattern)
      route404.redirectPage = routeWithSamePattern ? routeWithSamePattern.name : route404.name;
      route404.isAccessible = !routeWithSamePattern;
    }

    return route404;
  }

  getRouteWithPattern(patternToMath) {
    for (let routeName in this.routes) {
      if(this.routes.hasOwnProperty(routeName)){
        let route = this.routes[routeName];

        // we only take care about routes with same patterns that aren't the same
        if (!route.is404() && route.pattern === patternToMath) {
          return route;
        }
      }
    }

    return null;
  }

  interceptor(navigation, context) {
    return {intercept: false}
  }

  intercept(routeFrom, routeTo) {
    const navigation = {
      from:  {
        page: routeFrom.page,
        params: routeFrom.params
      },
      to: {
        page: routeTo.name,
        path: routeTo.pattern,
        params: routeTo.params
      }
    };
    return { ...this.interceptor(navigation, this.interceptorContext), ...navigation};
  }

  updateInterceptorContext(ctx) {
    this.interceptorContext = Object.assign({}, this.interceptorContext, ctx);
  }

  setInterceptorContext(ctx) {
    this.interceptorContext = Object.assign({}, ctx);
  }

  getInterceptorContext() {
    return Object.assign({}, this.interceptorContext);
  }

  /**
   *
   * @return {Subscription}
   */
  start() {
    /* istanbul ignore else */
    if (!_disposables) {
      var active = new SerialSubscription();

      _404Route = this._setup404();

      const source = this.useHistory ?
        this._observeStateChange() :
        this._observeHashChange();

      const subscription = source.pipe(
        distinctUntilChanged(),
        map(this.matchRoute.bind(this)),
        filter(r => {
          if (r && r.name === this.cancelledNavigation) {
            this.cancelledNavigation = undefined;
            this.isNavigationInProgress = false;
            if (this.currentRoute.name !== this.navigationStack.top().page) {
              this.navigationStack.push({ page: this.currentRoute.name, params: this.currentRoute.params})
            }
            return false;
          } else return true;
        })
      );

      subscription.forEach(route => {
        if (!this.hashIsDirty) {
          if (route) {
            const currentRouteName = this.currentRoute ? this.currentRoute.name : undefined;
            const currentRouteParams = this.currentRoute ? this.currentRoute.params : undefined;
            const routeFrom = this.navigationStack.createRoute(currentRouteName, currentRouteParams);
            const routeTo = this.navigationStack.createRoute(route.name, route.params);
            const interceptorResult = this.intercept(routeFrom, route);
            if (interceptorResult.intercept) {
              this.isNavigationInProgress = false;
              if (interceptorResult.redirect) {
                this.goReplacing(interceptorResult.redirect.page, interceptorResult.redirect.params);
              } else {
                this.go(currentRouteName, currentRouteParams, false);
                this.cancelledNavigation = currentRouteName;
              }
              if (this.channelManager) {
                setTimeout( () => this.channelManager.publishInterceptedNavigation(interceptorResult), 0);
              }
              return;
            } else {
              // NavigationStack computes the effective new current based on the skip navigation list
              // so it can be that the newRoute is different to the route from window.location
              const newRouteName = this.navigationStack.update(routeFrom, routeTo).page;
              if (newRouteName !== routeTo.page) {
                this.go(newRouteName, undefined, false);
                return;
              }
            }
            _currentRoute = route;
            var disposable = new Subscription(() => this.currentRoute);
            active.add(disposable);
            this.currentRoute.handler();
            this.handler(this.currentRoute);
          } else if (_404Route && _404Route.redirectPage) {
            this.goReplacing(_404Route.redirectPage);
          }
        } else {
          this.hashIsDirty = false;
        }
      });

      _disposables = new Subscription(
        subscription,
        active
      );
    }

    return _disposables;
  }

  stop() {
    if (_disposables) {
      _disposables.unsubscribe();
      _disposables = null;
    }
    this.isNavigationInProgress = false;
  }

  destroy() {
    this.stop();
    this.routes = {};
  }

  getPath(routeName, params) {
    const route = this.routes[routeName];
    if (route) {
      return route.path(params);
    } else {
      console.error('Wrong route name: %s, valid route names: %s', routeName, Object.keys(this.routes).join(', '));
    }
  }

  newNavigation(name) {
    return {
      'from': this.currentRoute ? this.currentRoute.name : undefined,
      'to': name
    }
  }

  reverseNavigation(nav) {
    return {
      from: nav.to,
      to: nav.from
    };
  }

  go(name, params, replace, skipHistory) {
    if (this.isNavigationInProgress) {
      return;
    }

    if (skipHistory !== undefined) {
      const newNav = this.newNavigation(name);
      const reverseNav = this.reverseNavigation(newNav);
      reverseNav.skipHistory = skipHistory;
      this.navigationStack.addSkipNavigation(reverseNav);
    }

    const sanitizedName = name.replace(Router.LTRIM_SLASH, '');
    const path = this.getPath(sanitizedName, params);
    if (path !== this._getHashPath()) {
      this.isNavigationInProgress = true;
      this.updatePathInBrowser(path, replace);
    }
  }

  back() {
    const navigation = {};
    if (this.navigationStack.length > 1) {
      let fromRoute = this.navigationStack.pop();
      let auxFromRoute = fromRoute;
      let backRoute = this.getLastRoute();

      while (this.navigationStack.isSkipNavigation({from: auxFromRoute.page, to: backRoute.page}) && this.navigationStack.length>1) {
        auxFromRoute = this.navigationStack.pop();
        if (this.navigationStack.length > 0) {
          backRoute = this.getLastRoute();
        }
      }

      const page = backRoute.page;
      const params = backRoute.params;

      navigation.from = fromRoute;
      navigation.to = backRoute;

      this.go(page, params);
    } else {
      navigation.from = this.getLastRoute();
      navigation.to = this.getLastRoute();
    }
    return navigation;
  }

  updatePathInBrowser(path, replace) {
    if (this.useHistory) {
      if (replace) {
        this.historyReplaceState(path);
      } else {
        this.historyPushState(path);
      }
    } else {
      if (replace) {
        this.locationReplace(path);
      } else {
        this.locationHash(path);
      }
    }
  }

  goReplacing(name, params) {
    this.go(name, params, true);
  }

  historyReplaceState(path) {
    history.replaceState(null, null, path);
  }

  historyPushState(path) {
    history.pushState(null, null, path);
  }

  locationReplace(path) {
    location.replace('#!' + path);
  }

  locationHash(path) {
    location.hash = '#!' + path;
  }

  /**
   * Get last route from stack.
   *
   * @returns {Object} Last route from stack.
   */
  getLastRoute() {
    return this.navigationStack.top();
  }

  /**
   * Initialize router stack.
   */
  init() {
    this._clearStack();
  }

  /**
   * Clear the router stack.
   */
  _clearStack() {
    this.navigationStack.clear();
  }

  /**
   * Clear the router stack until given page is found on router stack.
   */
  clearStackUntil(targetPage) {
    this.navigationStack.clearUntil(targetPage);
  }
}
