import EventEmitter from 'events';

const maxNumListeners = 20;

class CustomEventEmitter extends EventEmitter {
  listenToOnce(node, name, callback) {
    node.addEventListener(name, function cb (event) {
      callback();
      event.currentTarget.removeEventListener(event.type, cb);
    });
  }
}

const eventManager = new CustomEventEmitter();

eventManager.setMaxListeners(maxNumListeners);

export default eventManager;


